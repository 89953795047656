<script>
    import { apiUrl } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import ProductCardButtons from "@bivan/components/ProductCardButtons";
    import ProductColorList from "@bivan/components/ProductColorList";
    import ProductPrice from "@bivan/components/ProductPrice";

    export default {
        name: "ProductCard",
        components: {
            ProductPrice,
            ProductCardButtons,
            ProductColorList,
        },
        props: {
            product: Object,
            position: Number,
            isSearch: Boolean,
            isAvalible: Boolean,
            isSmall: Boolean,
            currentColors: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            let isPreOrder = false;
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                // проверка на предзаказ
                currentOffers = this.product.offers.filter((offer) => !offer.available);
                isPreOrder = true;
            }

            return {
                currentOffers,
                offer: currentOffers[0],
                offerId: currentOffers[0].id,
                previewImage: currentOffers[0].previewImage?.medium,
                price: currentOffers[0].price,
                discountPrice: currentOffers[0].discountPrice,
                discountPercent: currentOffers[0].discountPercent,
                inCart: this.product.inCart || false,
                loading: false,
                isPreOrder,
                previewImageFromOffer: "",
            };
        },
        computed: {
            nameProduct() {
                return this.product.shortName ? this.product.shortName : this.product.name;
            },
            scrImage() {
                return this.previewImageFromOffer ? apiUrl(this.previewImageFromOffer) : "";
            },
            isDiscount() {
                return this.discountPercent;
            },
        },
        methods: {
            apiUrl,

            selectColor(idColor) {
                let { id, previewImage, price, discountPrice, available } =
                    this.currentOffers.filter((offer) => offer.color.id === idColor)[0];
                this.offerId = id;
                this.previewImageFromOffer = previewImage.medium;
                this.price = price;
                this.discountPrice = discountPrice;
                this.isPreOrder = !available;
                this.inCart = false;
            },

            sendDataLayerEvent() {
                sendAnalytics.send(sendAnalytics.events.productClick, {
                    name: this.product.name,
                    offerId: this.offerId,
                    price: this.discountPrice,
                    position: this.position,
                });
            },
        },
    };
</script>

<template>
    <div class="product-card" :class="{ 'product-card--sm': isSmall }">
        <div class="product-card__img">
            <div v-if="isDiscount" class="product-card__sale-label">
                -{{ discountPercent }}%
            </div>

            <router-link
                @click.native.prevent="sendDataLayerEvent"
                :to="{
                    name: 'Product',
                    params: { slug: product.slug },
                }"
                class="product-card__img-link js-lazy-load"
                tabindex="-1"
            >
                <img
                    class="product-card__img-product"
                    :data-src="apiUrl(previewImage)"
                    :src="scrImage"
                    alt=""
                />
                <div class="product-card__preloader">
                    <preloader :show="true" lg-size></preloader>
                </div>
            </router-link>
        </div>

        <div v-if="offer.color && offer.available" class="product-card__colors">
            <product-color-list
                :offers="currentOffers"
                :current-offer="offer"
                @select-color="selectColor"
            ></product-color-list>
        </div>
        <div v-else class="product-card__colors-spy"></div>

        <router-link
            @click.native.prevent="sendDataLayerEvent"
            :to="{
                name: 'Product',
                params: { slug: product.slug },
            }"
            class="product-card__header"
        >
            <div
                class="product-card__name"
                :class="{
                    'product-card__name--small': isSmall,
                }"
            >
                {{ nameProduct }}
            </div>

            <div class="product-card__price">
                <product-price
                    :price="discountPrice"
                    :old-price="price"
                    :discount-count="discountPercent"
                    is-small-size
                ></product-price>
            </div>
        </router-link>

        <div class="product-card__footer">
            <product-card-buttons
                :offer-id="offerId"
                :product="product"
                :is-pre-order="isPreOrder"
                :in-cart="inCart"
                :discount-price="discountPrice"
                @on-added-to-cart="inCart = true"
            ></product-card-buttons>
        </div>
    </div>
</template>

<style lang="scss">
    .product-card {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 6px 10px 10px;
        background-color: #fff;
        border: 2px solid var(--primary-border);
        border-radius: var(--border-lg);
        transition: border-color 0.2s ease-in;
        &:hover,
        &:focus-within {
            border-color: #ececec;
            .product-card__name {
                color: var(--primary);
            }

            .product-card__img-link {
                img {
                    transform: scale(1);
                }
            }
        }

        @include bp($bp-desktop-sm-for-card) {
            padding: 10px 20px 20px;
        }
    }
    .product-card--sm {
        padding: 6px 10px 10px;
    }

    .product-card__img {
        position: relative;
        z-index: 2;
        overflow: hidden;
    }

    .product-card__preloader {
        opacity: 1;
    }

    .product-card__img-product {
        z-index: 1;
        display: none;
        animation: fadeIn 0.3s ease-in-out;

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: scale(0.9);
            }
            to {
                opacity: 1;
                transform: scale(0.98);
            }
        }

        &[lazy="loaded"] {
            display: block;
            & + .product-card__preloader {
                opacity: 0;

                & + .product-card__img-shadow {
                    opacity: 1;
                }
            }
        }
    }

    .product-card__sale-label {
        position: absolute;
        left: 0;
        bottom: 6px;
        z-index: 2;
        @include box(36px);
        color: var(--primary-text--invert);
        font-size: 12px;
        font-weight: bold;
        line-height: 38px;
        text-align: center;
        border-radius: 50%;
        background-color: var(--danger);

        @include bp($bp-desktop-sm) {
            bottom: 10px;
            @include box(40px);
            font-size: 14px;
            line-height: 42px;
        }
    }

    .product-card__img-link {
        position: relative;
        display: block;
        padding-bottom: 100%;
        outline-offset: -1px;
        outline: none;

        img {
            @include center-absolute(0);
            margin: auto;
            max-height: 100%;
            transform: scale(0.98);
            transition: 0.3s ease-in-out transform;

            &[lazy="loaded"] {
                & + .product-card__preloader {
                    opacity: 0;
                }
            }
        }
    }

    .product-card__price {
        display: block;
        margin-bottom: 15px;
    }

    .product-card__header {
        margin-top: 4px;
        outline: none;
        @include bp($bp-desktop-sm) {
            margin-top: 10px;
        }
    }

    .product-card__footer {
        margin-top: auto;
    }

    .product-card__name {
        display: block;
        position: relative;
        z-index: 2;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: var(--primary-text);
        margin-bottom: 5px;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            font-size: 18px;
            padding-right: 20px;
        }
    }
    .product-card__name--small {
        font-size: 13px;
        line-height: 18px;
    }

    .product-card__colors {
        padding: 0 2px;
        margin: 10px -4px 0;
    }

    .product-card__colors-spy {
        margin-top: 10px;
        height: 24px;
    }

    .product-card__feature {
        display: block;
        margin-bottom: 14px;
        outline: none;
    }

    .product-card__controls {
        margin-top: auto;
    }

    .product-card__z-top {
        position: relative;
        z-index: 4;
    }
</style>
