var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card",class:{ 'product-card--sm': _vm.isSmall }},[_c('div',{staticClass:"product-card__img"},[(_vm.isDiscount)?_c('div',{staticClass:"product-card__sale-label"},[_vm._v(" -"+_vm._s(_vm.discountPercent)+"% ")]):_vm._e(),_c('router-link',{staticClass:"product-card__img-link js-lazy-load",attrs:{"to":{
                name: 'Product',
                params: { slug: _vm.product.slug },
            },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('img',{staticClass:"product-card__img-product",attrs:{"data-src":_vm.apiUrl(_vm.previewImage),"src":_vm.scrImage,"alt":""}}),_c('div',{staticClass:"product-card__preloader"},[_c('preloader',{attrs:{"show":true,"lg-size":""}})],1)])],1),(_vm.offer.color && _vm.offer.available)?_c('div',{staticClass:"product-card__colors"},[_c('product-color-list',{attrs:{"offers":_vm.currentOffers,"current-offer":_vm.offer},on:{"select-color":_vm.selectColor}})],1):_c('div',{staticClass:"product-card__colors-spy"}),_c('router-link',{staticClass:"product-card__header",attrs:{"to":{
            name: 'Product',
            params: { slug: _vm.product.slug },
        }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('div',{staticClass:"product-card__name",class:{
                'product-card__name--small': _vm.isSmall,
            }},[_vm._v(" "+_vm._s(_vm.nameProduct)+" ")]),_c('div',{staticClass:"product-card__price"},[_c('product-price',{attrs:{"price":_vm.discountPrice,"old-price":_vm.price,"discount-count":_vm.discountPercent,"is-small-size":""}})],1)]),_c('div',{staticClass:"product-card__footer"},[_c('product-card-buttons',{attrs:{"offer-id":_vm.offerId,"product":_vm.product,"is-pre-order":_vm.isPreOrder,"in-cart":_vm.inCart,"discount-price":_vm.discountPrice},on:{"on-added-to-cart":function($event){_vm.inCart = true}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }